import request from '@/plugins/axios'

// 落查命中率添加
export function addRate(data) {
  return request({
    url: `/blastHitRate`,
    method: 'post',
    data
  })
}

// 查询
export function blastHitRate(current, limit, data) {
  return request({
    url: `/blastHitRate/${current}/${limit}`,
    method: 'post',
    data
  })
}
