<template>
  <div class="account-number">
    <div class="account-left">
      <div>
        <el-form :model="submitForm" ref="submitForm">
          <el-form-item
            prop="uid"
            :rules="{
              required: true,
              message: '请输入uid'
            }"
          >
            <el-input
              :autosize="{ minRows: 10, maxRows: 4 }"
              class="input-bottom"
              type="textarea"
              v-model="submitForm.uid"
              placeholder="请输入Telegram uid，单次提交多个请用逗号间隔,单次最多可提交1万条"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          size="mini"
          style="width: 100%; margin-top: 10px; border-radius: 16px"
          @click="submitFun"
          :loading="loading"
        >
          立即提交
        </el-button>
      </div>
    </div>
    <div class="account-right">
      <div class="top">
        <el-date-picker
          v-model="searchForm.startDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择开始时间"
          size="small"
        ></el-date-picker>
        <el-date-picker
          v-model="searchForm.endDate"
          type="date"
          style="margin-left:10px"
          size="small"
          value-format="yyyy-MM-dd"
          placeholder="选择结束时间"
        ></el-date-picker>

        <el-button
          type="primary"
          size="mini"
          style=" margin-left:10px"
          @click="searchFun"
          :loading="loading"
        >
          查询
        </el-button>
        <el-button
          class="el-icon-refresh-left"
          size="mini"
          style=" margin-left:10px"
          @click="refresh"
          :loading="loading"
        >
          刷新
        </el-button>
      </div>
      <div class="content" v-loading="loading">
        <el-table
          style="margin-bottom:10px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
          header-row-class-name="tableheader"
          :data="list"
          border
          :height="windowsHeight - 210"
        >
          <el-table-column prop="uid" label="Telegram uid"></el-table-column>
          <el-table-column
            prop="totalCount"
            label="总条数"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="hitCount"
            label="命中条数"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="hitRate"
            label="命中率/百分比"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="提交时间"
            width="170"
          ></el-table-column>
          <el-table-column
            prop="err"
            label="错误信息"
            width="170"
          ></el-table-column>
        </el-table>
        <pagination
          :total="total"
          :page-size="limit"
          :currentPage="current"
          :pagerCount="5"
          :background="false"
          :sizesArr="[30, 50, 100]"
          @currentChange="currentChange"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { addRate, blastHitRate } from '@/api/dropCheck/checkRate'

export default {
  name: 'CheckRight',
  data() {
    return {
      submitForm: {
        uid: ''
      },
      params: {},
      current: 1,
      limit: 30,
      total: 0,
      list: [],
      logList: [],
      searchForm: {
        startDate: '',
        endDate: ''
      },

      loading: false //加载状态
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },
  created() {
    this.getPage()
  },
  methods: {
    // 刷新
    refresh() {
      this.getPage()
    },
    // 查询
    searchFun() {
      this.current = 1
      this.getPage()
    },
    getPage() {
      this.loading = true
      blastHitRate(this.current, this.limit, this.searchForm).then(data => {
        this.list = data.items
        this.total = data.all_count
        this.loading = false
      })
    },

    submitFun() {
      this.$refs['submitForm'].validate(valid => {
        if (valid) {
          addRate(this.submitForm).then(data => {
            this.$message({
              type: 'success',
              message: '查询成功！'
            })
            this.current = 1
            this.submitForm.uid = ''

            this.getPage()
            this.$nextTick(() => {
              this.$refs['submitForm'].clearValidate()
            })
          })
          // localStorageFun('telegramAccount', this.submitForm)
          // this.logList = getLocalStorageFun('telegramAccount')
        }
      })
    },
    currentChange(val) {
      this.current = val
      this.getPage()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getPage()
    },
    selectLog(row) {
      this.$refs.searchIndex2Log.selectIndex = null
    }
  }
}
</script>

<style lang="scss" scoped>
.account-number {
  width: 100%;
  height: calc(100vh - 79px);
  overflow: hidden;
  background: rgb(240, 242, 245);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  .account-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
    .tips-text {
      position: absolute;
      top: 50%;
      font-size: 12px;
      font-weight: 300;
      color: #050505;
    }
    .input-bottom {
      margin-bottom: 10px;
    }
    .el-form-item {
      margin-bottom: 10px !important;
    }
    ::v-deep .el-form-item__error {
      top: 100% !important;
    }
    ::v-deep .el-input__inner {
      padding-right: 0;
    }
  }
  .account-right {
    width: calc(100% - 270px);
    height: 100%;
    // border: 1px solid #e5e5e5;

    padding-left: 5px;
    position: relative;
    overflow: hidden;
    .top {
      overflow: hidden;
      padding: 5px 10px;
      background: white;
    }
    .content {
      margin-top: 10px;
      background: white;
      padding: 10px;
    }
  }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
}
</style>
